import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { PageHeader01, Article03 } from "gatsby-theme-container";

const BlogTagsTemplate = ({ location, data }) => {
    const paths = location?.pathname?.split("/");
    const pageName = paths[paths.length - 1].split("-").join(" ");
    return (
        <Layout>
            <SEO pageName="Blog Tags Left Post" isBlogPost />
            <HeaderOne
                hasBorder
                data={{ ...data?.header, ...data?.navigation }}
            />
            <PageHeader01 data={{ pageName }} />
            <Article03
                data={{
                    items: data?.allArticle?.nodes,
                }}
            />
            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query BlogTagListTemplateQuery($slug: String!) {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        allArticle(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
            nodes {
                ...Article02
            }
        }
    }
`;

BlogTagsTemplate.propTypes = {
    pageContext: PropTypes.shape({
        currentPage: PropTypes.number,
        numberOfPages: PropTypes.number,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default BlogTagsTemplate;
